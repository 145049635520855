<template>
    <helpCenter />
</template>

<script>
// @ is an alias to /src
import helpCenter from "@/components/help-center";
export default {
  name: "help_center",
  components: {
    helpCenter,
  }
};
</script>