<template>
<div class="app-page">
  <Topbar1_2></Topbar1_2>
    <div class="bg">
  <div class="center_body">
  <el-tabs :tab-position="tabPosition" v-model="activename" @tab-click="handleClick" >
    <el-tab-pane label="帮助中心" name="title" disabled="disabled" ></el-tab-pane>
    <el-tab-pane v-for="(item,i) in help_class" :key=i :label="item.class_name" :data-clid="item.class_id"  :name="i.toString()" >
      <ul id="content"><li v-for="(item,i) in content" :key=i>
        <div class="title">{{parseInt(i)+1}}、{{item.title}}</div>
        <p class="ans" v-html="item.content"></p>
        </li></ul>
    </el-tab-pane>
  </el-tabs>
  </div>
  </div>
  </div>
</template>

<script>
import Topbar1_2 from '@/components/topbar1_2'
export default {
  name: "help-center",
  // inject:['app'],
  components: { 
  Topbar1_2
  },
  data() {
    return {
      tabPosition:"left",
      help_class:"",
      disabled:true,
      s:"order",
      activename:"0",
      content:"",
      id:"",
    };
  },
  beforeMount() {
    var that=this;
      $.ajax({
          async:false,
          type:'post',
          url:this.$store.state.user.z_url+'/index.php?s=/api/Helps/getalltype',
          success:function(res){
              res=JSON.parse(res);
            let results=[];
            for(let i in res.data){
                results.push(res.data[i])
            }
            that.help_class=results;
          },
          error : function(res) {
            alert('查询错误');
            }
        });
        let params={'class_id':"1"};
        $.ajax({
          async:false,
          type:'post',
          url:this.$store.state.user.z_url+'/index.php?s=/api/Helps/getcontent',
          data:params,
          dataType:'json',
          success:function(res){ 
            that.content=res.data;
          },
          error : function(res) {
            alert('查询错误');
            }
        })
        
  },
  methods: {
    handleClick(tab,event){
      var that=this;
      that.id=parseInt(tab.name)+1;
      let params={'class_id':that.id};
        $.ajax({
          async:false,
          type:'post',
          url:this.$store.state.user.z_url+'/index.php?s=/api/Helps/getcontent',
          data:params,
          dataType:'json',
          success:function(res){
             that.content=res.data;
          },
          error : function(res) {
            alert('查询错误');
            }
        })
    }
  }
};
</script>

<style lang="less" scoped>
.app-page{position: relative;}
.bg{
    width: 100%;
    height: 850px;
    background-color: rgba(242, 243, 250, 1);
    position: absolute;
    top: 80px;
    .center_body{width: 1250px;height: 96%;position: absolute;left: 0;right: 0;top: 0;bottom: 0;margin: auto auto;}
    .center_body /deep/ .el-tabs__nav{height: 100%;}
    .center_body /deep/ .el-tabs__nav-scroll{height:100%}
    .center_body /deep/ .el-tabs__nav-wrap{height: 100%;}
    .center_body /deep/ .el-tabs__header{height: 90%;}
    .center_body /deep/ .el-tabs--left {height: 100%;background-color: #fff;}
    .center_body /deep/ .el-tabs__item {width: 200px;height: 60px;font-size: 18px;text-align: center;line-height: 60px;}
    .center_body /deep/ #tab-title{background-color: #3743D6;color: #fff;}
}
#content{
  .title{height:17px;
font-size:16px;
font-family:Microsoft YaHei;
font-weight:bold;
color:rgba(68,68,68,1);
line-height:24px;margin-top: 17px;letter-spacing: 2px;}
.ans{
font-size:14px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(68,68,68,1);
margin-top: 10px;
letter-spacing: 2px;
}

}
</style>