<template>
  <div class="topbar">
    <div class="logo">
      <img src="@/assets/logo.png" @click="toindex()" title="返回首页" />
    </div>
    <!-- 导航栏 -->
    <div class="top-bar">
    <span @click="toindex()">首页</span>
      <span class="p_type">产品类型 <ul class="shenq"><li @click="sup_apply()">供应商申请</li><li @click="agent_apply()">代理商申请</li></ul></span>
      <span @click="to_help_center()">帮助中心</span>
      <span @click="to_mymanage()">我的管理</span>
</div>
<div class="right_nav">
  <img src="@/assets/nav_message_b.svg" @click="in_mail()">
<span class="mail" @click="in_mail()">站内信</span>
<span></span>
<div class="quit" v-if="this.$store.state.login.islogin">
  <em class="uname" v-if="this.$store.state.user.store_name=='店铺'">{{this.$store.state.user.phone}}</em>
  <em class="uname" v-else>{{this.$store.state.user.store_name}}</em>&nbsp;
  <span>|</span>&nbsp;<span @click="quit()">退出</span></div>
<b v-else @click="login_on">{{this.$store.state.user.user}}</b>
</div>
<!-- 导航栏 -->
  </div>
</template>

<script>
export default {
  name: "topbar1",
  // inject:['app'],
  data() {
    return {
      user: "",
      pwd: "",
      islogin: false,
      nickname:"昵称",
      activeIndex: '3',
      state:'',
      checkinfo:'',
    };
  },
  beforeCreate(){
    this.islogin = this.$store.state.login.islogin;
    this.user=this.$store.state.user.user;
    this.show=!this.login;
  },
  mounted() {
    this.$parent.$parent.$parent.sskj = false;
    this.nickname=this.$store.state.user.user;
  },
  methods: {
     login_on(){
      this.$router.push('./Login');
    },
    quit(){
      this.$router.push('./Login');
    },
    toindex(){
        this.$router.push('./homepage');
    },
    to_help_center(){
        this.$router.push('./help_center');
    },
    in_mail(){
      if(this.$store.state.login.islogin){
        let newpage = this.$router.resolve({ 
      path: './In_mail',
      query:{
          username:this.$store.state.user.user,
      }   
})
window.open(newpage.href, '_blank');
      }else{
        this.$message({
          message: "请先登陆",
          type: 'warning',
          center:'true',
          duration:'2000'
        });
      }
      
    },
    to_mymanage(){
        if(this.$store.state.login.islogin){
      }else{ this.$message({
          message: "请先登录",
          type: 'warning',
          center:'true',
        });}
        this.get_userinfo();
        this.getstaus();
        if((this.$store.state.user.user_type==0||this.$store.state.user.user_type==4)&&this.state==-1){
          this.$message({
          message: "请先申请成为供应商或代理商",
          type: 'warning',
          center:'true',
          offset: '60',
          duration:'1400'
        });
        }else if((this.$store.state.user.user_type==0||this.$store.state.user.user_type==4)&&this.state==0){
          this.$message({
          message: "您的权限正在审核中",
          type: 'warning',
          center:'true',
          offset: '60',
          duration:'1400'
        });
        }else if(this.$store.state.user.user_type==1||this.$store.state.user.user_type==2||this.$store.state.user.user_type==3||this.$store.state.user.user_type==5){
         if(this.$store.state.login.islogin){
              this.$router.push('./my_manage');
          }
        }
    },
    get_userinfo(){
      console.log(this.$store.state,"statestatestatestate");
      var that=this;
      var results='';
      if(this.$store.state.user.p_id==''){
        var params={'phone':this.$store.state.user.user,'type':2};
      }else{
        var params={'phone':this.$store.state.user.user,'type':1};
      } 
      //1是诚美，2是虚拟
        $.ajax({
          async:false,
          type:'post',
          url:this.$store.state.user.q_url+'/api/api/supplier_newInfo',
          data:params,
          dataType:'json',
          success:function(res){
            results=res;
        }         
            })
        if(results.data.type==1){
          that.$store.commit('user/phone',results.data.phone)
             that.$store.commit('user/p_id',results.data.p_id)
             that.$store.commit('user/m_id',results.data.m_id)
             that.$store.commit('user/real_name',results.data.Real_name);
          that.$store.commit('user/u_type',results.data.virtual);
          that.$store.commit('user/reg_time',results.data.createtime);
        if(results.data.store_name!=undefined){
          that.$store.commit('user/storename',results.data.store_name)
        }
         }else if(results.data.type==2){
           that.$store.commit('user/phone',results.data.phone)
        that.$store.commit('user/u_id',results.data.user_id)
             that.$store.commit('user/m_id',results.data.m_id)
          that.$store.commit('user/u_type',results.data.status);
        that.$store.commit('user/vir_id',results.data.user_id);
        that.$store.commit('user/real_name',results.data.Real_name);
        that.$store.commit('user/reg_time',results.data.regdate);
        if(results.data.store_name!=undefined){
          that.$store.commit('user/storename',results.data.store_name)
        }
            }
    },
    sup_apply(){
        if(this.$store.state.login.islogin){
        let u_type=this.$store.state.user.user_type;
        this.getstaus();
        // switch(parseInt(u_type)){
        //   case 0 :
        //     case 4 :
        //       case 1 :
        //         if(this.state==-1){
        //           this.$router.push('./suppliers_apply');
        //         }else{
        //           if(this.state==0){
        //           this.$router.push('./supplier_checking');
        //         }else if(this.state==1){
        //           this.$router.push('./suppliers_apply');
        //         }if(this.state==2){
        //           this.$router.push('./suppliers_apply');
        //         }
        //         } 
        //     break
        //     case 2 :
        //       case 3 :
        //       case 5 :
        //       this.$router.push('./suppliers_apply')
        // break;
        // }
        if(this.state==-1){
                  this.$router.push('./suppliers_apply');
                }else{
                  if(this.state==0){
                  this.$router.push('./supplier_checking');
                }else if(this.state==1){
                  this.$router.push('./suppliers_apply');
                }if(this.state==2){
                  this.$router.push('./suppliers_apply');
                }
                } 
        }else{
          this.$message({
          message: '请先登陆',
          type: 'warning',
          center:'true',
          duration:'2000',
        });
        }
      },
      getstaus(){
        var that=this;
        let params={'user_id':this.$store.state.user.vir_id,'p_id':this.$store.state.user.p_id};
        $.ajax({
          async:false,
          type:'post',
          url:this.$store.state.user.q_url+'/api/api/supplier_apply_status',
          data:params,
          dataType:'json',
          success:function(res){
            if(res.data==400){
              that.state=-1
            }else{
              that.checkinfo=res.data[0]
              that.state=that.checkinfo.status;
            }
        }         
            })
      },
      agent_apply(){
        
        if(this.$store.state.login.islogin){
          let u_type=this.$store.state.user.user_type;
        switch(parseInt(u_type)){
          case 0 :
            case 2 :
            this.$router.push('./agent_apply');
            break
            case 1 :
              case 3 :
              this.$message({
          message: '您已经是代理商了',
          type: 'error',
          center:'true',
          duration:'2000',
        });
        break;
        case 4 :
          case 5 : 
          this.$router.push('./agent_failure');
          break;
        }
        }else{
          this.$message({
          message: '请先登陆',
          type: 'warning',
          center:'true',
          duration:'2000',
        });
        }
      }
  }
};
</script>

<style lang="less" scoped>
.topbar{width: 85%;position: absolute;height: 75px;min-width: 1540px;
.top-bar{position: absolute;left: 510px;width: 800px;height: 60px;z-index: 2000;margin-top: 25px;
>span{width: 90px;height: 30px;line-height: 30px;color: #333;margin: 0 50px;font-size:16px;display: inline-block;text-align: center;font-weight: 400;
font-family:Microsoft YaHei;cursor: pointer;}
>span:nth-child(3){border-radius: 20px;color: #fff;background-color: #3D31E2;}
}
  .p_type{position: relative;
  .shenq{position: absolute;top:30px;left: 0;width: 90px;border: 1px solid #3d31e2;list-style: none;z-index: 8000;background-color:#fff;padding:0;display:none;
  li:hover{color: #3d31e2;}}}
  .shenq:before {content: "";color: red;position: absolute;display:block;width: 10px;height: 10px; top:-6px;left: 40px;border-bottom: 1px solid #3d31e2;border-right: 1px solid #3d31e2;
  transform: rotate(225deg) ;background-color: #fff;}
  li:first-child{margin-top: 10px;}
  .p_type:hover{.shenq{display: block;}}
.right_nav{height: 36px;line-height: 36px;position: absolute;width: 280px;z-index: 5000;right: 0;margin-top:27px;
>img{width: 24px;height: 24px;position: relative;top: 6px;cursor: pointer;color: #000;}
>span{font-size:14px;
font-family:Microsoft YaHei;
font-weight:400;
color:#333333;
display: inline-block;
position: relative;
left: 6px;}
.quit{display: inline-block;color: #333;
>span{cursor: pointer;}
}
.uname{font-size:14px;
font-style: normal;
font-family:Microsoft YaHei;
display: inline-block;
height: 36px;
text-align: center;
margin-left: 20px;
text-align: right;}
>b{font-size:14px;
font-family:Microsoft YaHei;
color:#333;
display: inline-block;
width: 70px;
height: 36px;
border:1px solid rgb(19, 18, 6);
border-radius:4px;
text-align: center;
margin-left: 60px;
cursor: pointer;}
}
.logo {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 360px;
  top: 14px;
  z-index: 5000;
  > img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
}


</style>